/**
 * Sign up file
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { useSnackbar } from "react-simple-snackbar";
import { motion } from "framer-motion";
import { Wrapper } from "../../../components/Wrapper";
import Model from "./model";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@mui/material/Chip";

const axiosInstance = axios.create({
  baseURL: "https://api.feelyoumusic.com",
  headers: {
    Accept: "application/json",
  },
});

export const Signup = () => {
  const history = useHistory();
  const [openSnackbar] = useSnackbar();
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [is13, setIs13] = useState(false);
  const [ip, setIP] = useState("0.0.0.0");
  const [open, setOpen] = React.useState(false);
  const [genreList, setGenreList] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState([]);
  const handleClose = () => {
    setOpen(false);
    history.push("/login");
  };
  const handleOpen = () => setOpen(true);
  const classes = useStyles();

  //creating function to load ip address from the API
  const getData = async () => {
    // const resd = await axios.get('https://get-ip-only.herokuapp.com/')
    // setIP(resd.data.ip)

    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };

  const getGenres = async () => {
    setLoading(true);
    try {
      const URL = "/genres/activeGenres";
      const response = await axiosInstance.get(URL);
      console.log("response", response.data);
      setGenreList(response.data);
      setLoading(false);
    } catch (error) {
      console.error("genresErr", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getGenres();
    getData();
  }, []);
  const signupHandler = async () => {
    try {
      if (selectedGenre.length === 0) {
        openSnackbar("Please select genre.");
        return false;
    }
      if (!is13) {
        openSnackbar("Please confirm that you are 13+ years old.");
        return false;
      }
      setLoading(true);
      if (user.name === "") {
        openSnackbar("please enter the name");
      } else if (user.email === "") {
        openSnackbar("please enter the email address");
      } else if (user.password === "") {
        openSnackbar("please enter the password");
      } else {
        const data = {
          name: user.name,
          email: user.email,
          password: user.password,
          confirmPassword: user.password,
          genres: selectedGenre.toString(),
          ip: ip,
          timeStamp: new Date().getTime(),
        };
        const URL = "/accounts/register";
        const response = await axiosInstance.post(URL, data);
        console.log("response", response.data);
        if (response.data) {
          handleOpen();
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("signupErr", error);
    }
  };

  const genreHandler = (genre) => {
    const list = [...selectedGenre];
    const itemIndexEmp = list.findIndex((item) => item === "");
    if (itemIndexEmp > -1) {
      list.splice(itemIndexEmp, 1);
    }
    const itemIndex = list.findIndex((item) => item === genre);
    if (itemIndex > -1) {
      list.splice(itemIndex, 1);
    } else {
      list.push(genre);
    }
    setSelectedGenre(list);
    // const trackGenre = [...trackData];
    // trackGenre[0].genres = list.toString();
    // setTrackData(trackGenre);
    console.log(selectedGenre);
  };

  return (
    <Wrapper dot={true} loader={loading}>
      {!loading && (
        <div
          className="flex w-100 mt-40"
          initial={{ opacity: 0, y: `100vh` }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              type: "spring",
              duration: 1,
              delay: 1,
            },
          }}
          exit={{ opacity: 0, y: "100vh" }}
        >
          {/* <h1 className="l-heading mt-30">Sign up</h1> */}

          <div className="lg-input-container mt-30">
            <input
              type="text"
              placeholder="Enter your name"
              className="lg-input"
              style={{
                fontFamily: "Roboto",
                borderBottomWidth: 1,
                borderBottomColor: "#DADADA",
                backgroundColor: "#fff",
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              value={user.name}
              onChange={({ target }) => {
                setUser({
                  ...user,
                  name: target.value,
                });
              }}
            />
            <input
              type="text"
              placeholder="Enter your email"
              className="lg-input"
              style={{
                fontFamily: "Roboto",
                borderBottomWidth: 1,
                borderBottomColor: "#DADADA",
                backgroundColor: "#fff",
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              value={user.email}
              onChange={({ target }) => {
                setUser({
                  ...user,
                  email: target.value,
                });
              }}
            />
            <input
              type="password"
              placeholder="Enter your password"
              className="lg-input"
              value={user.password}
              onChange={({ target }) => {
                setUser({
                  ...user,
                  password: target.value,
                });
              }}
            />
            {!loading && (
              <div
                className="mt-10"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className={"container " + classes.genreContainer}>
                  <div className="row">
                    <span className={classes.genreSpan}> What do you like listening to?</span>
                  </div>
                  <div className="row mt-10">
                    {genreList.map((genre) => {
                      return (
                        <Chip
                          color="secondary"
                          label={genre.genre}
                          variant={
                            selectedGenre.findIndex(
                              (item) => item === genre.genre
                            ) === -1
                              ? "outlined"
                              : ""
                          }
                          classes={classes.myChip}
                          key={genre.id}
                          onClick={() => {
                            genreHandler(genre.genre);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="mt-10"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                marginRight: 10,
                height: 15,
                width: 15,
                borderRadius: 5,
              }}
              value={is13}
              onChange={({ target }) => {
                setIs13(!is13);
              }}
            />
            <h5 className="heading13"> I am over 13 years</h5>
          </div>
          <motion.button
            type="submit"
            className="btn-outline text mt-40 pointer"
            onClick={signupHandler}
          >
            Sign up
          </motion.button>
        </div>
      )}

      <Model open={open} handleClose={handleClose} classes={classes} />
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  modelBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: 54,
    width: "60%",
    fontFamily: "Roboto",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.up("sm")]: {
      padding: 26,
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: 26,
    },
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  linkIcon: {
    color: "#9738f6",
    fontSize: "20px !important",
  },
  subtitle: {
    fontSize: "14px !important",
    fontWeight: "300 !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px !important",
    },
  },
  resendDiv: {
    marginTop: "10%",
  },
  link: {
    color: "#9738f6 !important",
    fontWeight: "400 !important",
  },
  cross: {
    float: "right",
    marginTop: "-40px",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.up("sm")]: {
      marginTop: "-16px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-16px",
    },
  },
  genreSpan: {
    fontSize: "1.8rem",
    color: "#9b9b9b",
    fontFamily: "Roboto",
  },
  genreContainer: {
    padding: "20px",
    borderTop: "1px solid #dadada",
    "& .MuiChip-root": {
      marginLeft: "4px !important",
      marginBottom: "4px",
      fontSize: "1.8rem !important",
      fontWeight: "400 !important",
      fontFamily: "Roboto !important",
      borderRadius: "10px !important",
      background: "linear-gradient(69.5deg, rgba(189, 73, 255, 0.99) 18.6%, rgb(254, 76, 227) 85.9%) !important",
      height: "26px !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important",
      },
    },
    '& .MuiChip-outlinedSecondary': {
      color: "#a39d9d !important",
      background: "#F8F8F8 !important",
      border: "none !important"
    },
    '& .MuiChip-label':{
      fontSize: "1.8rem !important",
      fontWeight: "400 !important",
      fontFamily: "Roboto !important",
    }
  },
  myChip: {
    fontSize: "12px",
    marginLeft: "4px",
  },
}));
