import React, {useState, useEffect} from "react";
import {useHistory} from "react-router";
import {motion} from "framer-motion";
import {Wrapper} from "../../../components/Wrapper";
import {useLocation} from 'react-router-dom'

import Loader from "react-js-loader";

export const Welcome = () => {
  const [name, setName] = useState("");
  const history = useHistory();
  const location = useLocation()

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setName(user.name);

      setTimeout(() => {
        const splited = location.pathname.split('/')
        history.push(`/music/${splited[2]}/${splited[3]}`);
      }, 2000);
    }
  };

  return (
    <Wrapper end={true}>
      <motion.div
        className='flex w-100 bottom-position'
        id='forgot'
        initial={{opacity: 0, y: `100vh`}}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          type: "spring",
          duration: 1,
        }}
        exit={{opacity: 0}}
      >
        <h1 className='l-heading mt-30'>{`Hi ${name ?? ""}`},</h1>
        <h1 className='l-heading'>Welcome to Feelyou!</h1>
        <p className='heading' style={{fontSize: "2.8rem", margin: "2.8rem"}}>
          {" "}
          Loading Feelyou Music experience for you
        </p>
        <Loader type='spinner-default' bgColor={"#FFFFFF"} size={80} />
      </motion.div>
    </Wrapper>
  );
};
