import React, { useEffect } from "react";
import { Router } from "./navigation";
import { makeStyles } from '@material-ui/core/styles';
// import { useClearCacheCtx } from 'react-clear-cache';


export const App = () => {
  const classes = useStyles();
  //const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  // useEffect(() => {
  //   emptyCacheStorage()
  // }, []);
  // useEffect(() => {
  //   emptyCacheStorage()
  // }, [isLatestVersion]);

  return (
    <>
      <Router />
      <div className={classes.floatsm} />
    </>
  )
};

const useStyles = makeStyles((theme) => ({
  floatsm: {
    position: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url('https://app.feelyoumusic.com/static/media/streaming-through-spotify.36a24da7d6b9e73de534.png')`,
    top: '20px',
    right: '8px',
    zIndex: '1000',
    height: '100px',
    width: '160px',
    [theme.breakpoints.up('lg')]: {

    },
    [theme.breakpoints.down('sm')]: {
      width: '64px',
      height: '40px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '64px',
      height: '40px'
    },
  }
}));
