import React from 'react';
import {Container, Box, Typography, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CopyRight, Header} from '../../../components';
import Linear from '../../../assets/images/linear.png'


export const MissionScreen = () => {

    const classes = useStyles();
    return (
        <>
            <div className={classes.back}>
            <div style={{height:'750px'}} className={classes.root}>
                    <div className={classes.headerStyle}>
                        <div style={{width: '100%', }}>
                            <Header checked="mission" />
                        </div>
                    </div>
                    <Container className={classes.headingContainer}>
                        <Box component="div" className={classes.headingDiv}>
                            <Typography variant="h4" className={classes.heading} >
                                Our mission is to make

                            </Typography>
                            <Typography variant="h4" className={classes.heading} >
                                everyone a mental well being

                            </Typography>

                            <Typography variant="h4" className={classes.subHeading} >
                                Our mission to make everyone a mental health well being.  mission to make everyone a mental health well being. Our mission to make everyone a mental health well being.

                            </Typography>

                        </Box>
                    </Container>
                </div>
            </div>


            {/* <Container style={{height: '75%', display: 'flex', alignItems: 'flex-start'}}>

            </Container>

            <div style={{height: '15%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>

            </div> */}

            <Container className={classes.root1}>
                <Grid container >
                    <Grid item className={classes.grayBoxContainer}>
                        <div className={classes.grayBox}></div>
                    </Grid>
                    <Grid item className={classes.firstBoxHeading}>
                        <Typography variant="h4" className={classes.header4} >
                            Our vision

                        </Typography>
                        <Typography variant="h4" className={classes.header5} >
                            Our mission to make everyone a mental health well being.  mission to make everyone a mental health well being. Our mission to make everyone a mental health well being.

                        </Typography>
                    </Grid>
                </Grid>


            </Container>
            <Container className={classes.root1} style={{background: 'rgba(218, 218, 218, 0.2)'}}>
                <Grid container >
                    <Grid item

                        className={classes.howWe}>
                        <Typography variant="h4" className={classes.header4left} style={{marginBottom: 0}} >
                            How are we pursuing

                        </Typography>
                        <Typography variant="h4" className={classes.header4left} >
                            this mission

                        </Typography>
                        <Typography variant="h4" className={classes.header5} style={{width: 'auto', marginBottom: 15}}>
                            Our mission to make everyone a mental health well being. Our mission to make everyone a mental health well being. Our mission to make everyone a mental health well being.Our mission to make everyone a mental health well being.
                        </Typography>
                        <Typography variant="h4" className={classes.header5} style={{width: 'auto'}}>

                            Our mission to make everyone a mental health well being. Our mission to make everyone a mental health well being. Our mission to make everyone a mental health well being.Our mission to make everyone a mental health well being.
                        </Typography>
                    </Grid>
                    {/* <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '', width: '50%', }}>
                     <div style={{ height: 440, width: 440, background: 'rgba(218, 218, 218, 0.6)', borderRadius: 20 }}></div>
                   
                    </Grid> */}
                </Grid>


            </Container>

            <CopyRight />

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    root1: {
        width: '100%',
        height: '100vh',
        left: 0,
        top: 0,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',

        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // height: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            display: 'flex',
            // justifyContent: 'space-around',
            alignItems: 'center',
            height: 'auto',
        },
    },
    container: {
        width: '100%',
        paddingTop: theme.spacing(18),
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(35),
            maxWidth: '100%',
            display: 'flex',
            height: '100%',
        },

        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(25),
            maxWidth: '100%',
            display: 'flex',
            height: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(15),
            maxWidth: '100%',
            display: 'flex',
            height: '100%',
        },

    },
    root: {
        width: '100%',
        height: '100%',

        // background: 'linear-gradient(180deg, #9836FA 0%, rgba(0, 0, 0, 0.07) 100%)',
        backgroundImage: `url(${Linear})`,
        // backgroundColor: 'red',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // opacity: 0.5,
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },

    },
    headerStyle: {
        height: '100px',
        display: 'flex',
        paddingLeft: 100,
        paddingRight: 100,
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'flex-end',
            paddingTop: 15,
            height: 'auto',
            paddingLeft: 15,
            paddingRight: 15,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'flex-start',
            padding: 15,
            height: 'auto',
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    headingContainer: {
        height: '80%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        // paddingBottom: 70,
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 20,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        // [theme.breakpoints.down('md')]: {
        //     paddingBottom: 20,
        //     height: 'auto',
        //     width: '100%',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        // },
    },
    headingDiv: {
        width: '80%',
        [theme.breakpoints.down('xs')]: {

            width: '100%',
        },
    },
    back: {
        maxWidth: '100%',
        height: '750px',
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: '#5D5FEF',


    },
    heading: {
        color: '#fff',
        fontWeight: '900',
        textAlign: 'center',
        fontFamily: 'Circular Std',
        fontSize: '72px',
        // backgroundColor: '#000',
        // textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        maxWidth: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            width: '100%',
            fontSize: 25,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            width: '100%',
            fontSize: 45,
        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            fontSize: '60px',
        },
    },
    subHeading: {
        marginTop: 40,
        color: '#fff',
        fontWeight: '500',
        textAlign: 'center',
        fontFamily: 'Roboto',
        lineHeight: '28px',
        maxWidth: '100%',
        alignSelf: 'center',
        fontSize: 24,
        paddingRight: 25,
        paddingLeft: 25,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 15,
            paddingLeft: 15,
            fontSize: 18,
        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            fontSize: 24,
        },
    },
    header4: {
        fontSize: 40,
        marginBottom: 20,
        lineHeight: '52px',
        // width: 400,
        color: '#9836FA',
        fontWeight: '900',
        // fontFamily: 'Publico Headline',
        fontFamily: 'Circular Std',
        [theme.breakpoints.between('767', '1025')]: {
            fontSize: 52,
            marginBottom: 20,
            lineHeight: '68px',
        },
    },
    header4left: {
        fontSize: 52,
        marginBottom: 20,
        lineHeight: '68px',
        // width: 400,
        textAlign: 'left',
        color: '#9836FA',
        fontWeight: '900',
        // fontFamily: 'Publico Headline',
        fontFamily: 'Circular Std',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            fontSize: 40,
            lineHeight: '50px',
        },
        [theme.breakpoints.between('767', '1025')]: {
            fontSize: 52,
            marginBottom: 20,
            lineHeight: '68px',
            textAlign: 'center',
        },
    },
    header5: {
        fontSize: 20,
        width: 400,
        fontFamily: 'Roboto',
        lineHeight: '23px',
        color: 'rgba(4, 4, 4, 0.8)',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            fontSize: 18,
            textAlign: 'justify'
        },
        [theme.breakpoints.between('767', '1025')]: {
            fontSize: 35,
            marginBottom: 20,
            lineHeight: '68px',
            width: '100%',
            textAlign: 'justify'
        },
    },
    howWe: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: '50%',
        paddingLeft: 150,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: 15,
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
        },
        // [theme.breakpoints.up('sm')]: {
        //     marginTop: 0,
        //     width: '100%',
        //     fontSize: 30,
        // },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLeft: 15,
            paddingRight: 15,
        },
        [theme.breakpoints.between('767', '1025')]: {
            padding: 15,
            height: '100%',
        },
    },
    grayBoxContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '50%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
            marginTop: 15,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        },
        [theme.breakpoints.between('767', '1025')]: {
            height: 'auto',
            width: '100%',

        },
    },
    grayBox: {
        height: 440,
        width: 440,
        background: 'rgba(218, 218, 218, 0.6)',
        borderRadius: 20,
        [theme.breakpoints.down('xs')]: {
            height: 200,
            width: 200,
        },
        [theme.breakpoints.between('767', '1025')]: {
            marginTop: 120,

        },
    },
    firstBoxHeading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
        width: '50%',
        padding: 120,
        [theme.breakpoints.down('xs')]: {
            // height: 'auto',
            width: '100%',
            padding: 15,
            alignItems: 'center',
        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            width: '100%',
            padding: 20,
            alignItems: 'center',
        },
    },

}))
