/**
 * Email verify file
 */
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router";
import axios from "axios";
import {Wrapper} from "../../components/Wrapper";
import {useLocation} from "react-router-dom";

const axiosInstance = axios.create({
  baseURL: "https://api.feelyoumusic.com",
  headers: {
    Accept: "application/json",
  },
});

export const Verify = () => {
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  console.log("search", location)
  useEffect(() => {
    verifyMail();
  }, []);

  const verifyMail = async () => {
    try {
      setLoading(true);
      const urlToken = location.pathname.split('/')
      console.log("urlToken", urlToken[3].split('='));
      let tokenValue=urlToken[3].split('=');
      const data = {
        token: tokenValue[1],
      };
      const URL = "/accounts/verify-email";
      const response = await axiosInstance.post(URL, data);
      console.log(response.data);
      if (response.data) {
        setMsg(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log("verifyERr", error);
    }
  };

  return (
    <Wrapper dot={true} loader={loading} verify={true}>
      <div
        className='flex w-100'
        id='forgot'
        initial={{opacity: 0, y: `100vh`}}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            type: "spring",
            duration: 1,
            delay: 1,
          },
        }}
        exit={{opacity: 0, y: "100vh"}}
      >

        {msg !== "" && (
          <>
            <h3 className='l-heading mt-30' style={{width: '50%'}}>Email successfully verified, please click on login</h3>
          
            <button
              type='submit'
              className='btn-outline text mt-40 pointer'
              onClick={() =>
                history.push("/login")
              }
              initial={{opacity: 0}}
              animate={{opacity: 1}}

            >
              Login
            </button>
          </>
        )}
      </div>
    </Wrapper >
  );
};
