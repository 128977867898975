/**
 * This file is used to login.
 */
import React, { useState, usePr } from "react";
import { Buffer } from 'buffer';
import { useHistory } from "react-router";
import axios from "axios";
import { Wrapper } from "../../../components/Wrapper";
import { useSnackbar } from "react-simple-snackbar";
import './login.css'
import qs from 'qs';
const axiosInstance = axios.create({
  baseURL: "https://api.feelyoumusic.com",
  headers: {
    Accept: "application/json",
  },
});
var client_secret = '3270ca8a4dcc41149b4060c9d192e8d7';
var client_id = '4731d7bd7737454cad87c9cdc1e4038f'; // Your client id
const axiosInstanceSpotify = axios.create({
  baseURL: "https://accounts.spotify.com",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic ' + (new Buffer.from(client_id + ':' + client_secret).toString('base64')),

  },
  form: {
    grant_type: 'client_credentials'
  },
  json: true
});



export const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const history = useHistory();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [loading, setLoading] = useState(false);

  function getHashParams() {
    var hashParams = {};
    var e, r = /([^&;=]+)=?([^&;]*)/g,
      q = window.location.hash.substring(1);
    while (e = r.exec(q)) {
      hashParams[e[1]] = decodeURIComponent(e[2]);
    }
    return hashParams;
  }
  useState(() => {
    var params = getHashParams();

    var access_token = params.access_token,
      state = params.state
    console.log(access_token)
    if (access_token) {
      localStorage.setItem("access_token", access_token);
      history.push("/TrackConfirmation");
    }

  }, [])



  const auth_token = Buffer.from(`${client_id}:${client_secret}`, 'utf-8').toString('base64');










  const getAuth = async () => {
    try {
      //make post request to SPOTIFY API for access token, sending relavent info
      const token_url = 'https://accounts.spotify.com/api/token';


      const data = qs.stringify({ 'grant_type': 'client_credentials', 'scope': 'ugc-image-upload user-read-playback-state user-modify-playback-state user-read-currently-playing streaming app-remote-control user-read-email user-read-private playlist-read-collaborative playlist-modify-public playlist-read-private playlist-modify-private user-library-modify user-library-read user-top-read user-read-playback-position user-read-recently-played user-follow-read user-follow-modify' });

      const response = await axios.post(token_url, data, {
        headers: {
          'Authorization': `Basic ${auth_token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      //return access token
      return response.data.access_token;
      //console.log(response.data.access_token);   
    } catch (error) {
      //on fail, log the error in console
      console.log(error);
    }
  }

  const login = async () => {

    try {
      setLoading(true);
      if (user.email === "") {
        openSnackbar("please enter the email address");
      } else {
        if (user.email) {
          if (user.password === "") {
            openSnackbar("please enter the password");
          } else {
            const data = {
              email: user.email,
              password: user.password, //pass123
            };
            const URL = "/accounts/authenticate";
            const response = await axiosInstance.post(URL, data);
            if (response.data) {
              const URLa = "/genres/activeGenres";
              const responseG = await axiosInstance.get(URLa);

              localStorage.setItem("user", JSON.stringify(response.data));
              const access_token = await getAuth();
              localStorage.setItem("access_token", access_token);
              if(responseG.data){
                localStorage.setItem("genres", JSON.stringify(responseG.data));
                history.push("/TrackConfirmation");
              }
             
            }
          }
        } else {
          openSnackbar("please enter valid email address");
        }
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.log("login-Err", error.response);
      setLoading(false);
      console.log(error);
      openSnackbar(error.response.data.message);
    }
  };

  return (
    <Wrapper loader={loading}>
      <div
        className='flex w-100'
        id='login'
        initial={{ opacity: 0, y: `100vh` }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            type: "spring",
            duration: 1,
            delay: 1,
          },
        }}
        exit={{ y: "-100vh" }}
      >
        <h1 className='login-heading mt-30'>Login</h1>
        <div className='login-input-container mt-30'>
          <input
            type='email'
            value={user.email}
            placeholder='Enter your email'
            className='input'
            style={{
              fontFamily: 'Roboto',
              borderBottomWidth: 1,
              borderBottomColor: '#DADADA',
              backgroundColor: '#fff',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onChange={({ target }) => {
              setUser({
                ...user,
                email: target.value,
              });
            }}
          />
          <input
            type='password'
            value={user.password}
            placeholder='Enter your password'
            className='input'
            style={{
              fontFamily: 'Roboto',

            }}
            onChange={({ target }) => {
              setUser({
                ...user,
                password: target.value,
              });
            }}
          />
        </div>

        <button
          type='submit'
          className='btn-outline text mt-40 pointer'
          onClick={login}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}

        >
          Login
        </button>
        <button
          type='submit'
          className='btn text mt-30 pointer'
          onClick={() => {
            localStorage.setItem("dot", "forgot");
            history.push("/forgot");
          }}

        >
          Forgot password
        </button>
      </div>
    </Wrapper>
  );
};
