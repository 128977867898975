/**
 * Password reset file
 */
import React, { useState } from "react";
import { useHistory,useLocation } from "react-router";
import axios from "axios";
import { useSnackbar } from "react-simple-snackbar";
import { motion } from "framer-motion";
import { Wrapper } from "../../../components/Wrapper";

const axiosInstance = axios.create({
  baseURL: "https://api.feelyoumusic.com",
  headers: {
    Accept: "application/json",
  },
});

export const PasswordReset = () => {
  const params = useLocation();
  const [token, setToken] = useState(new URLSearchParams(params.search).get('token'));
  const history = useHistory();
  const [openSnackbar] = useSnackbar();
  const [user, setUser] = useState({
    password: "",
    confirmPassword:""
  });
  const [loading, setLoading] = useState(false);

  const resetHandler = async () => {
    if(token !== ''){
      try {
        setLoading(true);
        if (user.password === "") {
          openSnackbar("please enter the password");
        } else if (user.confirmPassword === "") {
          openSnackbar("please enter confirm password");
        } else {
          const data = {
            token: token,
            password: user.password,
            confirmPassword: user.confirmPassword,
          };
          const URL = "/accounts/reset-password";
          const response = await axiosInstance.post(URL, data).catch(function (error) {
            if (error.response) {
              openSnackbar(error.response.data.message);
              setLoading(false);
            }});

          if (response.data) {
           openSnackbar(response.data.message);
            history.push("/login");
  
          }else{
            console.log("response", response);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Password Reset error", error);
      }
    }
   
  };
  return (
    <Wrapper dot={true} loader={loading}>
      <div
        className='flex w-100'
        initial={{ opacity: 0, y: `100vh` }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            type: "spring",
            duration: 1,
            delay: 1,
          },
        }}
        exit={{ opacity: 0, y: "100vh" }}
      >
        <h1 className='l-heading mt-30'>Reset Password</h1>
        <div className='lg-input-container mt-30'>
          <input
            type='password'
            placeholder='Enter your new password'
            className='lg-input'
            value={user.password}
            onChange={({ target }) => {
              setUser({
                ...user,
                password: target.value,
              });
            }}
          />
        </div>
        <div className='lg-input-container mt-30'>
          <input
            type='password'
            placeholder='Re-type password'
            className='lg-input'
            value={user.confirmPassword}
            onChange={({ target }) => {
              setUser({
                ...user,
                confirmPassword: target.value,
              });
            }}
          />
        </div>


        <motion.button
          type='submit'
          className='btn-outline text mt-40 pointer'
          onClick={resetHandler}

        >
          Reset Password
        </motion.button>
      </div>
     
    </Wrapper >
  );
};
