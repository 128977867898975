import React from 'react';
import {Typography, Button as MyButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({

    root: {
        border: '1px solid #fff',
        backgroundColor: '#fff',
        width: 180,
        borderRadius: 50,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 5,
        alignItems: 'center',
        cursor: 'pointer',
    },
    textClass1: {
        color: '#9836FA',
        fontSize: 18,
        decoration: 'none',
        textTransform: 'capitalize',
        fontFamily: 'Roboto',
    },
    textClass2: {
        color: '#fff',
        fontSize: 18,
        decoration: 'none',
        textTransform: 'capitalize',
        fontFamily: 'Roboto',
    },
    label: {
        color: 'red'
    }

}))

export const Button = ({title, onPress, bgColor, color, variant, size}) => {
    const classes = useStyles();
    return (
        <MyButton className={classes.root}
            style={{backgroundColor: bgColor ? bgColor : '#fff'}}
            onClick={onPress}
            component="button">
            <Typography component='p' variant="p" className={color ? classes.textClass2 : classes.textClass1}>
                {title}
            </Typography>
        </MyButton>

    )
}

