/**
 * Landing file from here you will navigate to Login or sign up.
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import LogoImg from "../../../assets/images/tmLogo.png";

export default function useWindowSize() {
  const isSSR = typeof window !== "undefined";
  const [windowSize, setWindowSize] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);
}

export const Navigation = () => {
  const history = useHistory();

  return (
    <section className='welcome hr-center'>
      <div
        className='flex bottom'
        initial={{ opacity: 0, y: `100vh` }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          delay: 1,
          duration: 1,
          when: "beforeChildren", // before children transition =>one more property(afterChildren)
          staggerChildren: 0.4,
        }}
        exit={{ opacity: 0, y: "100vh" }}
      >

        <img src={LogoImg} className='logoImg' />
        <h1 className='heading mt-30'> Positive Vibes Through Music</h1>
        <span style={{color: '#fff', fontStyle: 'italic'}}>Powered by ESMR</span>
        <button
          type='submit'
          className='btn-outline text mt-40 pointer'
          onClick={() => {
            history.push("/signup");
          }}
          initial={{ opacity: 0, x: "100vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.5, duration: 1 }}

        >
          Sign up
        </button>
        <button
          type='submit'
          className='btn text mt-30 pointer'
          onClick={() => {
            history.push("/login");
          }}
          initial={{ opacity: 0, x: "100vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.6, duration: 1 }}

        >
          Login
        </button>
      </div>
    </section>
  );
};
