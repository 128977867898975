 /**
  * Route file
  */
import React from "react";
import {Route, Switch} from "react-router-dom";
import {Navigation, Login, HomeScreen, Signup} from "../pages";
import {Forgot} from "../pages/authv2/Login/Forgot";
import {Mood} from "../pages/authv2/Login/Mood";
import {Welcome} from "../pages/authv2/Login/Welcome";
import {Verify} from "../pages/authv2/Verify";
import {NagativeMood} from "../pages/authv2/Login/NagativeMood";
import {MissionScreen} from "../pages/auth/mission";
import {TeamScreen} from "../pages/auth/team";
import {TrackConfirmation} from "../pages/authv2/Login/TrackConfirmation";
import { PasswordReset } from "../pages/authv2/SignUp/PasswordReset"; 

export const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path='/' component={Navigation} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/signup' component={Signup} />
      <Route exact path='/forgot' component={Forgot} />
      <Route exact path='/mood/:nMoodId' component={Mood} />
      <Route exact path='/TrackConfirmation' component={TrackConfirmation} />
      <Route exact path='/NagativeMood' component={NagativeMood} />
      <Route exact path='/music/:nMoodId/:pMoodId' component={HomeScreen} />
      <Route exact path='/welcome/:nMoodId/:pMoodId' component={Welcome} />
      <Route exact path='/verify-email' component={Verify} />
      <Route exact path='/music/:nMoodId/:pMoodId' component={HomeScreen} />
      <Route exact path='/account/verify-email/:token' component={Verify} />
      <Route exact path='/account/reset-password' component={PasswordReset} />
      <Route exact path='/mission' component={MissionScreen} />
      <Route exact path='/team' component={TeamScreen} />
    </Switch>
  );
};
