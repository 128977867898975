import React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({

    bold: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: 'Circular Std',
        lineHeight: '23px'
    },
    semiBold: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '23px',
        display: 'flex',
        alignItems: 'center',

        color: '#FFFFFF'
    },
    boxLink: {
        cursor: 'pointer',
        borderWidth: 2,
    },
    boxText: {
        cursor: 'default',
        borderWidth: 2,
    }

}))

export const Text = ({
    title,
    onPress,
    center,
    variant,
    color,
    bold,
    fontSize,
    checked,
    lineHeight}) => {
    const classes = useStyles();
    return (
        <div className={variant === "link" ? classes.boxLink : classes.boxText} onClick={onPress}>
            <Typography className={bold === "bold" ? classes.bold : classes.semiBold} style={{
                fontSize: fontSize, color: color ? color : '#fff', lineHeight: lineHeight ? lineHeight : '23px',
                textAlign: center ? center : 'left'

            }}>
                {title}

            </Typography>
            {
                checked ?
                    <div style={{border: ' 1px #fff solid', width: '100%', marginTop: 10}}></div>
                    :

                    <div style={{border: ' 0px #fff solid', width: '100%', marginTop: 10}}></div>
            }
        </div>
    )
}

