import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DoneIcon, RelaxIcon } from "../../../assets/images";
import { useHistory } from "react-router";

function MoodModel(props) {
  const {
    open,
    handleClose,
    classes,
    moodList,
    selectedMood,
    moodHandler,
    submitChildMood,
    submitType,
    iterationComplete
  } = props;
  const history = useHistory();

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ borderRadius: '8px' }}
    >
      <Box className={classes.modelBox}>
        <div className="flex w-100">
          
      
          { moodList.length > 0 && (
            <>
              <h1 className="l-heading mt-30" style={{ color: "#040404" }}>
                How are you feeling?
              </h1>

              <div className="lg-input-container mt-30 scroll" style={{ height: 'auto' }}>
                {moodList.length > 0
                  ? moodList.map((item, index) => {
                    const isSelected = selectedMood?.id === item.id;

                    return (
                      <>
                        <div
                          className={`lg-mood center pointer  ${isSelected ? "selected-mood" : ""
                            }`}
                          onClick={moodHandler(item)}
                        >
                          <div
                            className="item-center "
                            style={{ position: "relative" }}
                          >
                            {item.emoticon ? (
                              <img
                                alt="emoIcon"
                                src={`https://app.feelyoumusic.com/static/media/emoticon/${item.emoticon}`}
                                style={{
                                  height: 24,
                                  width: 24,
                                  marginRight: 5
                                }}
                              />
                            ) : (
                              <RelaxIcon />
                            )}

                            <h2 className="title pl-5">{item.mood}</h2>
                          </div>
                          {isSelected && (
                            <div
                              style={{ position: "absolute", right: "14%" }}
                            >
                              <DoneIcon />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            height: 1,
                            backgroundColor: "#DADADA"
                          }}
                        ></div>

                      </>
                    );
                  })
                  : null}
                <div className={'center'}>
                  <button
                    type="submit"
                    className="btn-outline text mt-20 pointer"
                    style={{ backgroundColor: "#9836fa" }}
                    onClick={submitChildMood}
                  >
                    Submit
                  </button>
                </div>

              </div>
            </>
          ) }
          
          { iterationComplete  && (
            <div className="lg-input-container mt-0 scroll">
              <h3
                className="l-heading mt-0"
                style={{ color: "#000", fontSize: 30 }}
              >
                Your session is finished successfully

              </h3>
              <div className={'center'}>
                <button
                  type="submit"
                  className="btn-outline text mt-20 pointer"
                  style={{ backgroundColor: "#9836fa" }}
                  onClick={submitType}
                >

                  Start New Session
                </button>
                <button
                  type="submit"
                  className="btn-outline text mt-20 pointer"
                  style={{ backgroundColor: "#9836fa" }}
                  onClick={submitChildMood}
                >
                  Logout
                </button>
              </div>

            </div>
          )
          }


        </div>
      </Box>
    </Modal>
  );
}

export default MoodModel;
