import React, {useState} from 'react';
import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {Button} from '../button';
import CloseIcon from '@material-ui/icons/Close';
import CONSTANT from '../../constant'
import axios from 'axios'
export const Footer = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isSubmit, setisSubmit] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [music, setMusic] = useState("");

    const handleOpenDialog = () => {
        setisSubmit(false)
        setOpen(true);
    }
    const handleName = (e) => {
        
        setName(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    const handleMusic = (e) => {
        setMusic(e.target.value);
    }
    const validate = () => {
        if (name && email && music && ValidateEmail(email)) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }
    const ValidateEmail = (mail) => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(mail)) {
            return (true)
        }
        return (false)//api
    }
    const handleSubmit = async () => {
        if (isSubmit) {
            setOpen(false)
            return false
        }
        if (isValid) {
            const token = 'fym-token-eyJjdXJyZW50RGlyIjoiL2FwaS5mZWVseW91bXVzaWM';

            try {
                const formdataOtp = new FormData();
                formdataOtp.append("name", name);
                formdataOtp.append("email", email);
                formdataOtp.append("token", token);
                formdataOtp.append("your_music", music);
                console.log(formdataOtp)

                const config = {
                    headers: {'content-type': 'multipart/form-data'}
                }

                await axios.post(`${CONSTANT.Api.baseUrl}${CONSTANT.Api.endpoint.early}`, formdataOtp, config)
                    .then(response => {
                        console.log("response", response);
                        if (response.data.error === false) {

                            setisSubmit(true)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });


            } catch (error) {
                console.log('error>>>>', error)
                // return error
            }
            // if (isSubmit) {
            //     setName("");
            //     setEmail("");
            //     setMusic("")

            //     setIsValid(false)
            //     setOpen(false)
            //     setisSubmit(false)
            // } else {
            //     setisSubmit(true)

            // }

        }

    }


    return (
        <>

            <div className={classes.root}>
                <Box component="span" className={classes.textBesideButton}>

                    <p className={classes.header}>Be the first ones to know when Feelyou music is available.</p>
                    {/* <Text title="Be the first ones to know when Feelyou music is available." fontSize={20} variant="text" onPress={() => {}} /> */}

                </Box>
                <Box component="span" className={classes.tabCenter}>
                    <Button onPress={handleOpenDialog} title="Early Signup" size="medium" />
                </Box>
            </div>
            {/* <Container className={classes.backGround}>
                <Box component="span" style={{marginRight: 10, marginTop: 9}}>
                    <Text title="Background music" fontSize={14} lineHeight={"16px"} variant="text" onPress={() => {}} />

                </Box>
                <ToggleSwitch
                    id="daily"
                    small
                    disabled={false}
                    checked={isOn}
                    onChange={() => {setIsOn(!isOn)}}
                />

            </Container> */}
            {/* <Grid item lg={6}>
                  
                </Grid> */}



            <Dialog maxWidth='xs' onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    // onClose(event, reason)
                    setOpen(false)

                }
            }} open={open}>

                <DialogContent className={classes.dialogPading}>
                    <Box component="div"
                        onClick={() => setOpen(false)}
                        className={classes.closeBtn}>
                        <CloseIcon style={{fontSize: 25}} />
                    </Box>
                    <Box component="div" className={classes.form}>
                        <Typography className={classes.headeing1}>
                            {isSubmit ? "Thank you for signing up!" : "Feelyou Early Signup"}
                        </Typography>
                        <Typography variant="p" className={classes.headeing2}>
                            {isSubmit ? "We will send you Feelyou music updates on" : "Be the first ones to know when  Feelyou music is available."}
                        </Typography>
                        {isSubmit && <Typography variant="p" className={classes.email}>
                            {email}
                        </Typography>
                        }
                    </Box>
                    {!isSubmit &&
                        <Box>
                            <Box component="div" className={classes.form} style={{marginTop: 20}} >
                                <Typography variant="p" className={classes.label}>
                                    Your name
                                </Typography>
                                <input
                                    type="text"
                                    placeholder="For exmaple:  Lisa Kudrow"
                                    className={classes.textField}
                                    onChange={handleName}
                                    onKeyUp={validate}
                                    style={{fontFamily: 'Roboto', }}
                                />

                            </Box>
                            <Box component="div" className={classes.form} >
                                <Typography variant="p" className={classes.label}>
                                    Your email
                                </Typography>
                                <input type="text"
                                    placeholder="For exmaple:  lisa@gmail.com"
                                    className={classes.textField}
                                    onChange={handleEmail}
                                    onKeyUp={validate}
                                    style={{fontFamily: 'Roboto', }}

                                />

                            </Box>
                            <Box component="div" className={classes.form} >
                                <Box component="div" style={{marginBottom: 12}}>
                                    <Typography variant="p" className={classes.label}>
                                        Your music
                                    </Typography>
                                    <Typography variant="p" style={{marginLeft: 5, fontFamily: 'Roboto', color: 'rgba(4, 4, 4, 0.4)'}}>
                                        (song, soundtrack, score etc)
                                    </Typography>
                                </Box>
                                <input
                                    type="text"
                                    placeholder="For exmaple:  All I Ask by Adele"
                                    className={classes.textField}
                                    onChange={handleMusic}
                                    onKeyUp={validate}
                                    style={{fontFamily: 'Roboto', }}

                                />

                            </Box>
                        </Box>
                    }
                    <Box component="div" className={classes.submitButton} style={{marginTop: 20}}>
                        <Button onPress={handleSubmit} title={isSubmit ? "Close" : "Sign up"} color="#ff" bgColor={isValid ? "#9836FA" : "#DADADA"} size="medium" />
                    </Box>
                </DialogContent>
            </Dialog>

        </>

    )
}
const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        height: '70%',
        width: '100%',
        // backgroundColor: 'rgba(0,0,0,0.3)',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            width: '98%',

            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    tabCenter: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '21px',
        /* identical to box height */

        display: 'flex',
        alignItems: 'center',

        color: '#9836FA',
        height: '100%',
        justifyContent: 'center',
        marginRight: 35,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            // alignItems: 'flex-start',
            textAlign: 'center',
            marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginRight: 0,
        },

    },
    backGround: {
        display: 'none',
        // width: '80%',
        // position: 'absolute',
        left: 0,
        bottom: 12,
        alignItems: 'center',
        // backgroundColor: 'rgba(0,0,0,0.3)'
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            paddingLeft: 25,
            width: '100%',
            bottom: 15,
            paddingRight: 0,
        },

    },

    closeBtn: {
        position: 'absolute',
        right: 25,
        top: 25,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            right: 15,
            top: 15,
            cursor: 'pointer',
        }
    },
    form: {
        // marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15,
        width: '100%'
    },
    submitButton: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 24,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 24,
            width: '100%',
        },
    },
    headeing1: {
        color: '#9836FA',
        marginBottom: 15,
        fontSize: 20,
        width: '100%',
        fontFamily: 'Roboto',
    },
    headeing2: {
        width: '100%',
        // marginBottom: 30,
        fontSize: 30,
        fontWeight: '500',
        color: 'rgba(4, 4, 4, 0.8)',
        fontFamily: 'Roboto',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            // marginBottom: 30,
            fontSize: 30,
            fontWeight: '500',
            color: 'rgba(4, 4, 4, 0.8)',
        },
    },
    email: {
        width: '100%',
        marginTop: 16,
        marginBottom: 30,
        fontSize: 24,
        fontWeight: '500',
        color: 'rgba(4, 4, 4, 0.52)'
    },
    textBesideButton: {
        width: '25%',
        // height: 50,
        // marginTop: 10,
        // backgroundColor: 'red',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            width: '100%',
            // paddingLeft: 20,
            // paddingRight: 20,

        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            width: '100%',

            // marginBottom: 24,
        },

    },
    textField: {
        width: '80%',
        borderRadius: 45,
        height: 40,
        borderWidth: 1,
        borderColor: 'rgba(4, 4, 4, 0.4)',
        paddingRight: 20,
        paddingLeft: 20,
        outline: 'none',
    },
    label: {
        fontSize: 16,
        width: '100%',
        fontWeight: '500',
        color: '#9836FA',
        marginBottom: 12,
        fontFamily: 'Roboto',
    },
    dialogPading: {
        backgroundColor: '#fff',

        // paddingRight: 100,
        paddingTop: "50px !important",
        // paddingBottom: 100,
        paddingLeft: 30,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 10,
            paddingTop: "50px !important",
            paddingBottom: 10,
            paddingLeft: 20,
        },
    },
    header: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#FFFCFC',
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
            lineHeight: '21px',
            textAlign: 'center',
        },
    }


}))

