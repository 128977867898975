import React from 'react';
import {  Typography, Container } from '@material-ui/core';
// import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        // height: 200,
        marginTop:15,
        marginBottom:15,
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    content: {
        fontSize: 16,
        color: '#9836FA',
        fontFamily: 'Roboto',
        textAlign: 'ceter',
        display: 'flex'
    },
    rightYear: {
        fontSize: 16,
        color: 'rgba(4, 4, 4, 0.5)',
        fontFamily: 'Roboto',
        marginTop: 10,
        textAlign: 'ceter'
    }
}))
export const CopyRight = () => {
    // const history = useHistory();
    const classes = useStyles();
    return (
        <Container className={classes.root}>

            <Typography variant="h4" className={classes.content} >
                Feelyou Music  <Typography variant="h4" className={classes.content} style={{paddingLeft:5, color: 'rgba(0,0,0,0.8)', }} > Positive vibes through music</Typography>
            </Typography>
            <Typography variant="h4" className={classes.rightYear} >
                Copyright Feelyou @2021
            </Typography>
        </Container>

    )
}

