var appConfiguration = null;

export const setAppConfiguration = (config) => {
    appConfiguration = config;
};

export const getAppConfiguration = () => {
    return appConfiguration;
};
export const LOCALES = {
    
};
