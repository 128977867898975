import { post, get, delte } from './requests';
import { getAppConfiguration } from '../config';

const login = async ({ data }) => {
    const result = await post(getAppConfiguration().BASE_URL + 'UserLogin/Userlogin', { data });
    return result;
};

const register = async ({ data, token }) => {
    const result = await post(getAppConfiguration().BASE_URL + 'User/add', { data, token });
    return result;
}

export {
    login,
    register
};
