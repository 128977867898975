import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Image from '../../assets/images/background.png';
import Linear from '../../assets/images/linear.png'
const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        // position:'absolute',
        // left: 0,
        // top: 0,
        // background: 'linear-gradient(180deg, #53168F 0%, rgba(0, 0, 0, 0.5) 100%)',
        // backgroundImage: `url(${Linear})`,
        backgroundSize:'contain',
        // backgroundPosition: 'center',
        // opacity: 0.5,
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
        },
    },
    back: {
        width: '100%',
        height: '100%',

        backgroundImage: `url(${Image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {

            backgroundSize: 'cover',
            backgroundPosition: '85% 50%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: '85% 50%',
        },
    }
}))


export const Backgeound = ({children}) => {
    const classes = useStyles();
    return (
        <div className={classes.back}>
            <div className={classes.root}>
                {children}
            </div>
        </div>
    )
}

