/**
 * Mail sent popup
 */
import React from "react";
import { Modal, Box, Typography, Link } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router";

function Model(props) {
  const {
    open,
    handleClose,
    classes,
  } = props;
  const history = useHistory();

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          history.push("/login");
         // handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ borderRadius: '8px' }}
    >


      <Box >

        <div className={classes.modelBox}>
          <div className={classes.cross} style={{ float: 'right', cursor: 'pointer' }}>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className="flex w-100 center">
            <Typography variant="h5" className={classes.wrapIcon}>
              <CheckCircleIcon className={classes.linkIcon} /> A verification link has been sent to your email to complete your registration
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Tip: Check your spam folder incase email was incorrectly identified.
            </Typography>
            {/* <div className={classes.resendDiv}>
              <Typography variant="body1" className={classes.subtitle}>
                Didn't receive the email? <Link href="#" className={classes.link}>Resend Email</Link>
              </Typography>
            </div> */}
          </div>
        </div>

      </Box>


    </Modal>
  );
}

export default Model;
