/**
 * This file is used to recover the password.
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { Wrapper } from "../../../components/Wrapper";
import { useSnackbar } from "react-simple-snackbar";

const axiosInstance = axios.create({
  baseURL: "https://api.feelyoumusic.com",
  headers: {
    Accept: "application/json",
  },
});

export const Forgot = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  useEffect(() => { }, []);

  const forgot = async () => {
    try {
      setLoading(true);
      if (email.length === 0) {
        openSnackbar("please enter the email address");
      } else {
        let regex = new RegExp("[a-z0-9]+@gmail.com");
        if (regex.test(email)) {
          const data = {
            email,
          };
          const URL = "/accounts/forgot-password";
          const response = await axiosInstance.post(URL, data);

          if (response.data) {
            openSnackbar(response.data.message);
            setTimeout(() => {
              history.push("/login");
            }, 2000);
          }
        } else {
          openSnackbar("please enter valid email address");
        }
      }

      setTimeout(() => {
        setLoading(false);
      }, 1400);
    } catch (error) {
      console.error("forgot-Err", error);
      openSnackbar("Oops! Something went wrong!..");
      setLoading(false);
    }
  };

  return (
    <Wrapper loader={loading}>
      <div
        className='flex w-100'
        id='forgot'
        initial={{ opacity: 0, y: `100vh` }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            type: "spring",
            duration: 1,
            delay: 1,
          },
        }}
        exit={{ opacity: 0, y: "100vh" }}
      >
        <h1 className='l-heading mt-30'>Forget password</h1>
        <div className='sm-input-container mt-30'>
          <input
            type='text'
            placeholder='Enter your email'
            className='input'
            value={email}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </div>
        <button
          type='submit'
          className='btn-outline text mt-40 pointer'
          onClick={forgot}
          whileHover={{
            scale: 1.2,
            textShadow: "0px 0px 8px rgb(255, 255, 255)",
            boxShadow: "0px 0px 8px rgb(255, 255, 255)",
            transition: {
              duration: 0.3,
              yoyo: Infinity,
            },
          }}
        >
          Send
        </button>
        <button
          type='submit'
          className='btn text mt-30 pointer'
          onClick={() => {
            history.push("/login");
          }}
          whileHover={{
            scale: 1.2,
            textShadow: "0px 0px 8px rgb(255, 255, 255)",
            transition: {
              duration: 0.3,
              yoyo: Infinity,
            },
          }}
        >
          Login
        </button>
      </div>
    </Wrapper>
  );
};
