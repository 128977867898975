import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DoneIcon, RelaxIcon } from "../../../assets/images";
import { useHistory } from "react-router";

function FirstModel(props) {
  const {
    open,
    handleClose,
    classes
  } = props;
  const history = useHistory();

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ borderRadius: '8px' }}
    >
      <Box className={classes.modelBox}>
        <div className="flex w-100">
          <div className="lg-input-container mt-0 scroll">
            <h3
              className="l-heading mt-0"
              style={{ color: "#000", fontSize: 16 }}
            >
              Feelyou music will be measuring your emotional state to help get you to your desired goal vibe. As you listen, you will be periodically asked if the music playing is helping you obtain your goal vibe. This will instantly update the measurment bar above.

            </h3>
            <div className={'center'}>
              <button
                type="submit"
                className="btn-outline text mt-20 pointer"
                style={{ backgroundColor: "#9836fa" }}
                onClick={handleClose}
              >

                OK
              </button>
            </div>

          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default FirstModel;
