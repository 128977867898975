import {
    createSlice,
    createSelector,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import {login, register, chnagePassword} from '../../api/routes';
const authAdapter = createEntityAdapter();

const initialState = authAdapter.getInitialState({
    status: 'idle',
    token: '',
    username: null,
    pasw: '',
    chngpass: null,
    connectedId: null,
    deviceId: null,
    isAuthenticate: false,
    isSignUP:false
});

export const doLogin = createAsyncThunk(
    'auth/Login',
    async ({data}) => {
        try {
            const response = await login({data});
            if (response) {

                return response;
            }
        } catch (err) {
            return err;
        }
    },
);




export const doRegister = createAsyncThunk(
    'auth/Register',
    async ({data, token}) => {

        try {


            const response = await register({data, token})


            return response;


        }
        catch (err) {
            return Promise.reject('NETWORK_ERROR');

        }
    }
)



const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getToken(state, action) {

            state.token = action.payload.token;
        },
        clearToken(state, action) {
            state.token = null;
        },

        getData(state, action) {
            state.data = action.payload.response

        }
    },
    extraReducers: {
        [doLogin.fulfilled]: (state, action) => {
            state.token = action.payload;
            state.isAuthenticate = true
        },


        [doRegister.fulfilled]: (state, action) => {
            state.token = action.payload;
            state.isSignUP = true
        },



    },




});
export const {
    selectAll: selectAllProfiles,
    selectById: selectProfilesById,
} = authAdapter.getSelectors((state) => {
    return state.authReducer;
});


export const {
    getToken,
    clearToken,
} = authSlice.actions;

export default authSlice.reducer;
