import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import SubjectIcon from "@mui/icons-material/Subject";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import LandscapeIcon from "@mui/icons-material/Landscape";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useHistory } from "react-router";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@material-ui/core/styles';
import ReorderIcon from '@mui/icons-material/Reorder';
import { ProfileModal } from "./ProfileModal";
const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    flexDirection: 'row-reverse',
    '& 	.MuiAccordionSummary-expandIconWrapper': {
      color: '#e4c1eb',
      marginRight: '4px'
    }
  }
}));


const DrawerComponent = (props) => {
  const { toggleDrawer, state } = props;
  const history = useHistory();
  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    setSession(JSON.parse(localStorage.getItem("iterationData")))
  }, [window.localStorage.getItem("sessionId"), window.localStorage.getItem("iterationNo")]);



  return (
    <>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <Box
          sx={{ width: 280 }}
          role="presentation"
          // onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          style={{ paddingRight: 29, paddingTop: 10, paddingLeft: 24 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <IconButton aria-label="delete" onClick={toggleDrawer("left", false)}>
              <Typography variant="p" style={{ color: "#000" }}>
                <CloseIcon />
              </Typography>
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%"
            }}
          >
            <div>
              <p
                style={{ fontSize: 15, color: "#04040466", fontFamily: "Roboto" }}
              >
                RECENTLY PLAYED
              </p>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginTop: 30
                }}
              >


                <ReorderIcon
                  style={{ fontSize: 18, color: "#e4c1eb", marginRight: 5 }}
                />
                <div>
                  <p
                    style={{
                      fontSize: 18,
                      color: "#e4c1eb",
                      fontFamily: "Roboto"
                    }}
                  >
                    Sessions
                  </p>
                </div>
              </div>

              <div >
                {
                  loading ? (<p>Loading</p>) : session?.map((data, index) => {
                    if(session.length-1 !== index){
                      return (

                        <Accordion key={index + "a"} expanded={expanded === data.iteration_no} onChange={handleChange(data.iteration_no)}
                          style={{ boxShadow: "none", marginLeft: '4px', }}
                        >
                          <AccordionSummary
                            expandIcon={<AddIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={classes.accordionSummary}
                          >
  
                            <div style={{
                              flexShrink: 0,
                              color: "#e4c1eb",
                              fontFamily: "Roboto",
                              textDecoration: "uppercase",
                              fontSize: 14
                            }}>
                              Song Set {data.iteration_no}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails style={{
                            marginLeft: '36px',
                            marginTop: '-15px',
                            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                          }}>
                            {data?.tracks?.map((tracks, ind) => {
                              return (
                                <div
                                  style={{
                                    height: 12,
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "flex-start",
                                    marginTop: 8
                                  }}
                                  key={index + ind}
                                >
                                  <PlayArrowOutlinedIcon
                                    style={{ fontSize: 12, color: "#04040466", marginRight: 5 }}
                                  />
                                  <div>
                                    <p
                                      style={{
                                        fontSize: 12,
                                        color: "#04040466",
                                        fontFamily: "Roboto",
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis"
                                      }}
                                    >
                                      {tracks.trackName}
                                    </p>
                                    {/* <p
                       style={{
                         color: "#04040466",
                         fontSize: 12,
                         fontWeight: "400",
                         fontFamily: "Roboto",
                         marginTop: 5
                       }}
                     >
                       33 songs • 78 mins
                     </p> */}
                                  </div>
                                </div>
                              )
                            })}
  
                          </AccordionDetails>
                        </Accordion>
                      )
                    }
                   

                  })}

              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <p
                style={{ fontSize: 15, color: "#04040466", fontFamily: "Roboto" }}
              >
                SETTINGS
              </p>
              <div
                style={{
                  height: 35,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 15,
                  cursor: "pointer"
                }}
              >
                <AccountCircleIcon
                  style={{ fontSize: 20, color: "#040404CC", marginRight: 5 }}
                />
                <div
               // onClick={toggleDrawer("left", false),handleOpen}
                  onClick={() => {
                      handleOpen()
                    //history.push("/profile");
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: "#040404CC",
                      fontFamily: "Roboto"
                    }}
                  >
                    Profile
                  </p>
                </div>
              </div>
              <div
                style={{
                  height: 35,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  cursor: "not-allowed"
                }}
              >
                <SubjectIcon
                  style={{ fontSize: 20, color: "#04040466", marginRight: 5 }}
                />
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      color: "#04040466",
                      fontFamily: "Roboto"
                    }}
                  >
                    Playlist Settings
                  </p>
                </div>
              </div>

              <div
                style={{
                  height: 35,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  cursor: "not-allowed"
                }}
              >
                <LandscapeIcon
                  style={{ fontSize: 20, color: "#04040466", marginRight: 5 }}
                />
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      color: "#04040466",
                      fontFamily: "Roboto"
                    }}
                  >
                    Background Visuals
                  </p>
                </div>
              </div>

              <div
                style={{
                  height: 35,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  cursor: "not-allowed"
                }}
              >
                <VideoLabelIcon
                  style={{ fontSize: 20, color: "#04040466", marginRight: 5 }}
                />
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      color: "#04040466",
                      fontFamily: "Roboto"
                    }}
                  >
                    Device Settings
                  </p>
                </div>
              </div>

              <div
                style={{
                  height: 35,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  cursor: "not-allowed"
                }}
              >
                <SettingsIcon
                  style={{ fontSize: 20, color: "#04040466", marginRight: 5 }}
                />
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      color: "#04040466",
                      fontFamily: "Roboto"
                    }}
                  >
                    Accout Settings{" "}
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  window.localStorage.clear();
                  history.push("/login");
                }}
                style={{
                  height: 35,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <LogoutRoundedIcon
                  style={{ fontSize: 20, color: "#040404CC", marginRight: 5 }}
                />
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      color: "#040404CC",
                      fontFamily: "Roboto"
                    }}
                  >
                    Logout
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
      <ProfileModal  open={open}
                    handleClose={handleClose} />
    </>
  );
};

export default DrawerComponent;
