import React from 'react';
import {Container, Box, Typography, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CopyRight, Header} from '../../../components';
import Linear from '../../../assets/images/linear.png'
export const TeamScreen = () => {
    const classes = useStyles();
    return (
        <>
            <Container className={classes.back}>
                <div style={{height:'750px'}} className={classes.root}>
                    <div className={classes.headerStyle}>
                        <div style={{width: '100%', }}>
                            <Header checked="team" />
                        </div>
                    </div>
                    <Grid container className={classes.headerGrid}>
                        <Grid item className={classes.column1}  >

                            <div className={classes.headingContainer}>
                                <Box component="div" style={{width: '100%', }}>
                                    <Typography variant="h4" className={classes.heading} >
                                        Making everyone

                                    </Typography>
                                    <Typography variant="h4" className={classes.heading} >

                                        a mental well being
                                    </Typography>
                                    <div style={{marginBottom: 20, }}></div>
                                    <Typography variant="h4" className={classes.subHeading} style={{marginBottom: 20, }}>
                                        Our mission to make everyone a mental health well being.  mission to make everyone a mental health well being. Our mission to make everyone a mental health well being.Our mission to make everyone a mental health well being.
                                    </Typography>
                                    <Typography variant="h4" className={classes.subHeading} >
                                        Our mission to make everyone a mental health well being. Our mission to make everyone a mental health well being. Our mission to make everyone a mental health well being.  </Typography>
                                </Box>
                                <div className={classes.smallScreen}>
                                    <Typography variant="h4" className={classes.owner} >
                                        Sarah Sheldon

                                    </Typography>
                                    <Typography variant="h4" className={classes.desig} >
                                        Founder & CEO

                                    </Typography>
                                </div>
                            </div>

                        </Grid>
                        <Grid className={classes.column2} >
                            <div className={classes.headingContainer1}>
                                <div className={classes.largeScreen}>
                                    <Typography variant="h4" className={classes.owner} >
                                        Sarah Sheldon

                                    </Typography>
                                    <Typography variant="h4" className={classes.desig} >
                                        Founder & CEO

                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </Container>
            <Container className={classes.firstDiv}>
                <Grid container >
                    <Grid item className={classes.grayBoxContainer}>
                        <div style={{height: 'auto', marginTop: 10}}>
                            <div>
                                <div className={classes.circle1}></div>
                                <div className={classes.circle1} ></div>
                            </div>
                            <div>
                                <div className={classes.circle1}></div>
                                <div className={classes.circle1} ></div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item className={classes.firstBoxHeading}>
                        <Typography variant="h4" className={classes.header6}>
                            How Sarah builds her team
                        </Typography>
                        <Typography variant="h4" className={classes.header5} >
                            Our mission to make everyone a mental health well being.Our mission to make everyone a mental health well being.Our mission to make everyone a mental health well being.Our mission to make everyone a mental health well being.

                        </Typography>
                    </Grid>
                </Grid>


            </Container>
            <div className={classes.secondDiv}>
                <Container>
                    <Box className={classes.secondBoxHeading}>
                        <Typography variant="h4" className={classes.header4} style={{fontSize: 40}} >
                            Our team
                        </Typography>
                        <Typography variant="h4" className={classes.seconBoxSubHead} >
                            One liner to compliment the team
                        </Typography>
                    </Box>
                    <Grid container spacing={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Grid item sm={3}>
                            <div className={classes.circle}></div>
                            <Box className={classes.nameDesigContainer}>
                                <Typography variant="h4" className={classes.name} >
                                    Name
                                </Typography>
                                <Typography variant="h4" className={classes.circleDesig} >
                                    Design
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={3}>
                            <div className={classes.circle}></div>
                            <Box className={classes.nameDesigContainer}>
                                <Typography variant="h4" className={classes.name} >
                                    Name
                                </Typography>
                                <Typography variant="h4" className={classes.circleDesig} >
                                    Engineering
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item sm={3}>
                            <div className={classes.circle}></div>
                            <Box className={classes.nameDesigContainer}>
                                <Typography variant="h4" className={classes.name} >
                                    Name
                                </Typography>
                                <Typography variant="h4" className={classes.circleDesig} >
                                    Marketing
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={3}>
                            <div className={classes.circle}></div>
                            <Box className={classes.nameDesigContainer}>
                                <Typography variant="h4" className={classes.name} >
                                    Name
                                </Typography>
                                <Typography variant="h4" className={classes.circleDesig} >
                                    Product
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </div>
            <CopyRight />


        </>
    )
}
const useStyles = makeStyles((theme) => ({

    firstDiv: {
        width: '100%',
        // height: '750px',
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            paddingTop: 20,
            paddingBottom: 20
            // paddingBottom:100,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            paddingTop: 20,
            paddingBottom: 20
            // paddingBottom:100,
        },
        [theme.breakpoints.between('767', '1025')]: {
            width: '100%',
            // height: '750px',
            display: 'flex',
            paddingBottom: 100,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
        },
    },
    headerStyle: {
        height: '100px',
        display: 'flex',
        paddingLeft: 100,
        paddingRight: 100,
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'flex-end',
            paddingTop: 15,
            height: 'auto',
            paddingLeft: 15,
            paddingRight: 15,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'flex-start',
            padding: 15,
            height: 'auto',
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    container: {
        width: '100%',
        paddingTop: theme.spacing(18),
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(35),
            maxWidth: '100%',
            display: 'flex',
            height: '100%',
        },

        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(25),
            maxWidth: '100%',
            display: 'flex',
            height: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(15),
            maxWidth: '100%',
            display: 'flex',
            height: '100%',
        },

    },
    root: {
        width: '100%',
        height: '100%',
        // background: 'linear-gradient(180deg, #9836FA 0%, rgba(0, 0, 0, 0.07) 100%)',
        backgroundImage: `url(${Linear})`,
        // backgroundColor: 'red',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // opacity: 0.5,
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },

    },
    back: {
        maxWidth: '100%',
        // height: '750px',
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: '#F951D4',


    },
    heading: {
        color: '#fff',
        fontWeight: '900',
        // fontFamily: 'Publico Headline',
        fontFamily: 'Circular Std',
        // backgroundColor: '#000',
        maxWidth: '100%',
        // display:'flex',
        fontSize: 72,
        lineHeight: '84px',
        // textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            width: '100%',
            fontSize: 45,
            lineHeight: '50px',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            width: '100%',
            fontSize: 40,
            textAlign: 'center',
            lineHeight: '50px',
        },
        [theme.breakpoints.between('767', '1025')]: {
            width: '100%',
            fontSize: 62,
            lineHeight: '84px',
            textAlign: 'center',
        },
    },
    subHeading: {
        color: '#fff',
        fontWeight: '500',
        fontFamily: 'Roboto',
        // backgroundColor: '#000',
        maxWidth: '90%',
        fontSize: 20,
        lineHeight: '23px',

        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            width: '100%',
            maxWidth: '100%',
            fontSize: 18,
            textAlign: 'justify'
        },
        [theme.breakpoints.between('767', '1025')]: {
            width: '100%',
            maxWidth: '100%',
            textAlign: 'center'
        },
        [theme.breakpoints.between('767', '1025')]: {
            width: '100%',
            maxWidth: '100%',
            textAlign: 'center'
        },
    },
    header4: {
        fontSize: 40,
        marginBottom: 20,
        lineHeight: '52px',
        // width: 400,
        color: '#9836FA',
        fontWeight: '900',
        // fontFamily: 'Publico Headline'
        fontFamily: 'Circular Std',
    },
    header6: {
        fontSize: 52,
        marginBottom: 20,
        width: 'auto',
        paddingRight: 20,
        color: '#9836FA',
        fontWeight: '900',
        // fontFamily: 'Publico Headline',
        fontFamily: 'Circular Std',
        lineHeight: '68px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            textAlign: 'center'
        },
        [theme.breakpoints.between('767', '1025')]: {
            width: '100%',
            textAlign: 'center'
        },
    },
    header5: {
        fontSize: 20,
        width: 'auto',
        fontFamily: 'Roboto',
        wordSpacing: 5,
        color: 'rgba(4, 4, 4, 0.8)',
        lineHeight: '23px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            textAlign: 'justify'
        },
    },
    secondDiv: {
        height: '850px',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-around',
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: 'center',
        display: 'flex',
        background: 'rgba(218, 218, 218, 0.2)'
    },
    headerGrid: {
        width: '100%',
        height: '80%',
        display: 'flex',
        alignItems: 'flex-end',
        paddingLeft: 100,
        paddingRight: 100,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            paddingLeft: 15,
            paddingRight: 15,
        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            paddingLeft: 50,
            paddingRight: 50,
        },

        // 
    },
    column1: {
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        height: '100%',
        width: '70%',
        // paddingBottom: 40,
        [theme.breakpoints.down('xs')]: {
            width: '100%',

        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            width: '100%',
            paddingLeft: 15,
            paddingRight: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },

    },
    column2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '30%',
        // paddingBottom: 40,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLeft: 15,
            paddingRight: 15,
        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            width: '100%',
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    headingContainer: {
        // height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        // paddingBottom: 40,
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            paddingBottom: 0,

            marginTop: 40,
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            paddingBottom: 40,
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    headingContainer1: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 0,
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            justifyContent: 'center',
            paddingBottom: 40,
            alignItems: 'center',
        },
    },
    owner: {
        fontSize: 32,
        color: '#fff',
        fontWeight: '900',
        fontFamily: 'Publico Headline',
        lineHeight: '42px',
    },
    desig: {
        fontSize: 20,
        marginTop: 15,
        color: '#fff',
        fontWeight: '500',
        lineHeight: '23px',
        fontFamily: "Roboto"
    },
    grayBoxContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
        width: '50%',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            width: '100%',
            paddingLeft: 15,
            paddingRight: 15,
        },
        [theme.breakpoints.between('767', '1025')]: {
            // height: 'auto',
            width: '100%',
            padding: 100,
        },
    },
    grayBox: {
        height: 440,
        width: 440,
        background: 'rgba(218, 218, 218, 0.6)',
        borderRadius: 20,
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            width: '100%',
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    firstBoxHeading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        padding: 120,
        height: '100vh',
        width: '50%',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            width: '100%',
            padding: 15,
        },
        [theme.breakpoints.between('767', '1025')]: {
            height: 'auto',
            width: '100%',
            padding: 15,
            alignItems: 'center',
        },
    },
    secondBoxHeading: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        marginBottom: 90,
        marginTop: 15,
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            width: '100%',
            // padding: 15,
            marginBottom: 10
        },
    },
    seconBoxSubHead: {
        fontSize: 32,
        fontFamily: 'Roboto',
        color: 'rgba(4, 4, 4, 0.8)',
        marginTop: 10,
        textAlign: 'center',
        lineHeight: '37px'
    },
    circle: {
        height: 300,
        width: 300,
        borderRadius: 360,
        background: 'rgba(218, 218, 218, 0.6)',
        margin: 5,
        [theme.breakpoints.down('xs')]: {
            width: 140,
            height: 140,
        },
        [theme.breakpoints.between('767', '1025')]: {
            width: 140,
            height: 140,
        },
    },
    circle1: {
        height: 230, width: 230, float: 'left',
        borderRadius: 360,
        background: 'rgba(218, 218, 218, 0.6)',
        margin: 5,
        [theme.breakpoints.down('xs')]: {
            width: 120,
            height: 120,
            float: 'left',
        },

    },

    name: {
        fontSize: 16,
        fontFamily: 'Roboto',
        marginTop: 15,
        textAlign: 'ceter'
    },
    circleDesig: {
        fontSize: 16,
        fontFamily: 'Roboto',
        color: 'rgba(4, 4, 4, 0.8)',
        marginTop: 10, textAlign: 'ceter'
    },
    nameDesigContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
    },
    smallScreen: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: 15,
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        [theme.breakpoints.between('767', '1025')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
    },
    largeScreen: {
        display: 'flex',
        height: '60%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            display: 'none',
        },
    }
}))
