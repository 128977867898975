import React, {useState} from 'react';
import {Container, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Text} from '../text';
// import ReactPlayer from 'react-player'
import Dialog from '@material-ui/core/Dialog';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import {useHistory} from 'react-router';
import useWindowDimensions from '../../hooks/useDimensions'
import {YoutubePlayer} from "reactjs-media";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
export const Menu = ({checked, isMobile}) => {
    const classes = useStyles();
    const history = useHistory();
    const [openDemo, setOpenDemo] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const {width, height} = useWindowDimensions()
    const handleClose = () => {
        setOpenDemo(false)
    }
    return (
        <>

            <Container className={classes.root}>
                <Box component="span" className={clsx(classes.tabCenter)} >
                    <Text title="Our mission" variant="link" bold="semiBold" checked={checked === "mission"} onPress={() => {
                        history.push("/mission")
                    }} />
                </Box>
                <Box component="span" className={classes.tabCenter}>
                    <Text title="Our story" variant="link" bold="semiBold" checked={checked === "team"} onPress={() => {
                        history.push("/team")

                    }} />
                </Box>
                <Box component="span" className={classes.tabCenter}>
                    <Text title="Watch a demo" variant="link" bold="semiBold" checked={checked === "watch"} onPress={() => {setOpenDemo(true)}} />
                </Box>
            </Container>
            
            <Container className={classes.hemburger}>
                {
                    <IconButton
                        onClick={() => setOpenDrawer(true)}>
                        <MenuIcon style={{color: '#fff', fontSize: 28}} />
                    </IconButton>
                }

                {/* openDrawer */}
                <Drawer
                    anchor={'left'}
                    fullwidth={true} open={openDrawer} onClose={() => setOpenDrawer(false)}>
                    <Container style={{width: width, height: height}}>
                        <Box component="div"
                            onClick={() => setOpenDrawer(false)}
                            className={classes.closeBtn}>
                            <CloseIcon style={{color: '#fff', fontSize: 35}} />
                        </Box>
                        <div style={{display: 'flex', paddingLeft: 20, height: '70%', width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Box style={{marginTop: 50}}>
                                <Box component="span" className={classes.tabCenter1} >
                                    <Text
                                        title="Our mission"
                                        variant="link"
                                        fontSize={24}
                                        color="#fff"
                                        bold="bold"
                                        center="left"
                                        checked={checked === "mission"}
                                        onPress={() => {
                                            history.push("/mission")
                                        }} />
                                </Box>
                                <Box component="span" className={classes.tabCenter1}>
                                    <Text
                                        title="Our story"
                                        variant="link"
                                        fontSize={24}
                                        color="#fff"
                                        bold="bold"
                                        center="left"
                                        checked={checked === "team"}
                                        onPress={() => {
                                            history.push("/team")
                                        }} />
                                </Box>
                                <Box component="span" className={classes.tabCenter1}>
                                    <Text
                                        title="Watch a demo"
                                        variant="link"
                                        fontSize={24}
                                        color="#fff"
                                        bold="bold"
                                        checked={checked === "watch"}
                                        center="left"
                                        onPress={() => {setOpenDemo(true)}} />
                                </Box>
                                <Box component="span" className={classes.tabCenter1} style={{marginTop: 30}}>
                                    <FacebookIcon className={classes.social}
                                        onClick={() => {
                                            window.location.href = "https://www.facebook.com/feelyouapp";

                                        }} />
                                    <InstagramIcon className={classes.social} onClick={() => {
                                        window.location.href = "https://www.instagram.com/feelyou_music/";

                                    }} />
                                    <LinkedInIcon className={classes.social}
                                    onClick={() => {
                                        window.location.href = "https://www.linkedin.com/company/positive-vibes-through-music-llc";

                                    }} />

                                </Box>
                            </Box>
                        </div>
                    </Container>
                </Drawer>
            </Container>
            {/* openDemo */}
            <Dialog maxWidth='fullwidth' fullwidth={true} onClose={handleClose} open={openDemo}>
                <Container style={{
                    height: '100%',
                    width: '100%',
                    padding: 0
                }}>
                    <YoutubePlayer
                        src="https://youtu.be/UZCO5k1Nu70" // Reqiured
                        className={classes.video}
                        width={'100%'}
                        height={'100%'}
                    />
                    {/* <ReactPlayer
                        // className={classes.video}
                        url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                    /> */}
                </Container>


            </Dialog>

        </>

    )
}


const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        width: '100%',
        // marginTop: 9,
        // backgroundColor:'red',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingRight: 124,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            paddingLeft: 0,
            paddingRight: 0,
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            // paddingLeft: 10,
            // position: 'absolute',
            right: 20,
            width: 'auto',
            top: 0,
            paddingLeft: 0,
            paddingRight: 0,


        },
    },
    hemburger: {
        display: 'none',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            paddingLeft: 0,
            paddingRight: 0,

        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            paddingLeft: 0,
            paddingRight: 0,

        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            paddingLeft: 0,
            paddingRight: 0,

        },
    },
    video: {
        height: 450,
        width: 700,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            // height: "100%",
            width: "100%",

        },
        [theme.breakpoints.down('sm')]: {
            // height: "100%",
            height: 250,
            width: "100%",

        },
        [theme.breakpoints.down('xs')]: {
            height: 150,
            width: "100",

        },
    },
    tabCenter: {
        display: 'flex',
        height: '100%',
        // width: '100%',
        paddingTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 35,
        [theme.breakpoints.down('md')]: {
            display: 'none',
            height: '100%',
            justifyContent: 'flex-start',
            // paddingLeft: 10,
            paddingRight: 0,
            color: 'rgba(4,4,4,0.8)',
            width: '100%',
            marginLeft: 0,
            textAlign: 'left',
            marginTop: 10,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            height: '100%',
            justifyContent: 'flex-start',
            // paddingLeft: 10,
            paddingRight: 0,
            color: 'rgba(4,4,4,0.8)',
            width: '100%',
            marginLeft: 0,
            textAlign: 'left',
            marginTop: 10,

        },

    },
    underline: {

    },
    tabCenter1: {
        display: 'flex',
        height: '100%',
        // width: '100%',
        justifyContent: 'flext-start',
        alignItems: 'flex-end',
        marginLeft: 35,
       
        [theme.breakpoints.down('md')]: {
            display: 'block',
            height: '100%',
            justifyContent: 'center',
            // paddingLeft: 10,
            paddingRight: 0,
            color: 'rgba(4,4,4,0.8)',
            width: '100%',
            textAlign: 'left',
             paddingBottom:10,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            height: '100%',
            // justifyContent: 'centert',
            // paddingLeft: 10,
            paddingRight: 0,
            color: 'rgba(4,4,4,0.8)',
            width: '100%',
            marginLeft: 0,
            textAlign: 'left',
            paddingBottom:10,

        },

    },

    closeBtn: {
        position: 'absolute',
        right: 28,
        top: 40,
        cursor: 'pointer'
    },
    social: {color: '#000', borderRadius: 360, marginRight: 15, fontSize: 20, padding: 5, backgroundColor: '#fff'}
}))
