import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { createStoreAndPersistor } from "generic";
import storage from "redux-persist/lib/storage";
import { App } from "./App";
import SnackbarProvider from "react-simple-snackbar";

import "./style/index.css";
import "./style/mobile-portrait.css";
import "./style/mobile-landscape.css";

const { persistor, store } = createStoreAndPersistor(storage);
ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
