import React, {useState, useEffect,useRef} from 'react';
import {Typography, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import './login.css'


export const Slider = ({title, onPress, variant, bold, slides, selectedSlide}) => {
    
    const classes = useStyles();
    const intervalRef = useRef();
    const [selectedSlider, setSelectedSlider] = useState(0);
useEffect(() => {
    const interval = setInterval(() => {
        console.log(Math.floor(Math.random() * (slides.length - 0)) + 0)
        setSelectedSlider(Math.floor(Math.random() * (slides.length - 0)) + 0)
            }, 5000);
            return () => clearInterval(interval);
  
}, [])
        //  const delay = 5000;

   
        // const [index, setIndex] = React.useState(0);
        // const timeoutRef = React.useRef(null);
      
        // function resetTimeout() {
        //   if (timeoutRef.current) {
        //     clearTimeout(timeoutRef.current);
        //   }
        // }
  
        // React.useEffect(() => {
        //     resetTimeout();
        //     timeoutRef.current = setTimeout(
        //       () =>
        //         {
        //             let temp = JSON.parse(JSON.stringify(selectedSlider))
        //             let count = temp + 1;
        //             if (slides.length > 1) {
        //                 if (count <= slides.length) {
        //                     console.log(count);
        //                     setSelectedSlider(count)
        //                 } else {
        //                     setSelectedSlider(0)
        //                 }
        //             } else {
        //                 setSelectedSlider(0) 
        //             }
        //         },
        //       delay
        //     );
        
        //     return () => {
        //       resetTimeout();
        //     };
        //   }, [selectedSlider]);
    //     function sizeObj(obj) {
    //         return Object.keys(obj).length;
    //       }

    // useEffect((slides) => {
    //     const interval = setInterval(() => {
    //         console.log("slides.length--11>",sizeObj(slides));
    //         setData();
    //     }, 5000);
    //     return () => clearInterval(interval);
    // }, [selectedSlider]);

    const setData = () => {
        let temp = JSON.parse(JSON.stringify(selectedSlider))
        let count = temp + 1;
        console.log(Array.isArray(slides))
       
        if (slides && slides.length > 1) {
            if (count <= slides.length) {
                console.log(count);
                setSelectedSlider(count)
            } else {
                setSelectedSlider(0)
            }
        } else {
            setSelectedSlider(0)
        }
    }
    return (

        <Box component="div" className={classes.root}>

            {slides?.map((child, index) => {
                
                if (index === Math.floor(Math.random() * (slides.length - 0)) + 0) {
                    return (
                        <Box component="div" style={{alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{textAlign: 'center', width: '50%'}}>
                                <Typography variant="p" className={classes.semiBoldActive} >
                                    {slides[selectedSlider].phrase}
                                </Typography>
                                <Typography className={classes.by} >
                                    {slides[selectedSlider].phraseBy}
                                </Typography>
                            </div>
                        </Box>
                    )
                }
                return null
            })}

            <ul className="dots">
                {slides?.map((child, index) => (
                    <li className={index === selectedSlider ? 'active' : ''}>
                        <button onClick={() => {
                            //
                        }}>{index + 1}</button>
                    </li>
                ))}
            </ul>
        </Box>
    )
}

export const useStyles = makeStyles((theme) => ({
    root: {

        // paddingTop:253,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        transition: 'all 600ms cubic-bezier(0.645, 0.045, 0.355, 1)',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            marginTop: 0,

        },
    },
    bold: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 20

    },
    semiBold: {
        // fontFamily: ' Publico Headline',
        fontFamily: 'Circular Std',
        fontStyle: 'italic',
        fontWeight: '900',
        fontSize: '62px',
        lineHeight: '61px',
        opacity: 0,
        transition: 'all 600ms ease',
        transitionDelay: '300ms',
        color: '#FFFCFC',
        marginBottom: 15,
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            width: '90%',
            fontSize: 35,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
            width: '100%',
            fontSize: 49,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            width: '100%',
            lineHeight: '27px',
            fontSize: 25,
            textAlign: 'center'
        },
    },
    semiBoldActive: {
        // fontFamily: ' Publico Headline',
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '37.5px',
        opacity: 1,
        transition: 'all 600ms ease',
        transitionDelay: '300ms',
        color: '#FFFCFC',
        marginBottom: 15,
        // [theme.breakpoints.down('md')]: {
        //     marginTop: 0,
        //     width: '90%',
        //     fontSize: 35,
        // },
        // [theme.breakpoints.up('sm')]: {
        //     marginTop: 0,
        //     width: '100%',
        //     fontSize: 49,
        // },
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: 0,
        //     width: '100%',
        //     lineHeight: '27px',
        //     fontSize: 25,
        //     textAlign: 'center'
        // },
    },
    by: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: ' 18px',
        lineHeight: ' 21px',
        marginTop: 40,
        color: '#FFFCFC',
        // width: '100%',
        opacity: 1,
        transition: 'all 600ms ease',
        transitionDelay: '300ms',
        [theme.breakpoints.down('md')]: {
            fontSize: ' 24px',
            lineHeight: ' 28px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: ' 24px',
            lineHeight: ' 28px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: ' 18px',
            lineHeight: ' 28px',
            textAlign: 'center'
        },

    },
    boxLink: {
        cursor: 'pointer'
    },
    boxText: {
        cursor: 'default'
    },
    activeDot: {
        color: '#fff',
        marginRight: 40,
        height: 10,
        width: 10,
    },
    dot: {
        color: 'rgba(255,255,255,0.5)',
        cursor: 'pointer',
        marginRight: 40,
        height: 12,
        width: 12,
    },
    dotContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 10,
        },

    },
    dots: {
        marginBottom: 20,
        display: 'flex',
    },

}))