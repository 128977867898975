import React from 'react';
import {Box} from '@material-ui/core';
import LogoImg from '../../assets/images/tmLogo.png'
import HeaderMobile from '../../assets/images/Headermobile.png'
import {useHistory} from 'react-router';


export const Logo = ({isMobile, height, width, }) => {
    const history = useHistory();

    return (
        <Box component="span" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {
            // history.push("/")

        }} m={1}>
            {
                isMobile ?
                    <img src={HeaderMobile} alt="logo" style={{height: 32, width: 99}} />
                    :
                    <img src={LogoImg} alt="logo" style={{height: height || 54, width: width || 260}} />

            }

        </Box >

    )
}

