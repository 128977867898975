import React, {useState, useEffect} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Logo} from '../logo';
import {Menu} from '../menus';
import {Text} from '../text'
import IconButton from '@material-ui/core/IconButton';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import SONG from '../../assets/audio/background.wav'
import 'react-jinke-music-player/assets/index.css'
export const Header = ({checked, }) => {
    const classes = useStyles();
    // const [audio] = useState(typeof Audio !== "undefined" && new Audio(SONG)); //this will prevent rendering errors on NextJS since NodeJs doesn't recognise HTML tags neither its libs.
    const [isPlaying, setIsPlaying] = useState(true);

    useEffect(() => {
        const audioEl = document.getElementsByClassName("audio-element")[0];
        if (isPlaying) {
            audioEl.play()
        } else {
            audioEl.pause()
        }
    }, [isPlaying])

    useEffect(() => {
        const audioEl = document.getElementsByClassName("audio-element")[0];
        if (audioEl) {
            audioEl.play()
        }
    }, [])



    const handleSound = async (val) => {
        setIsPlaying(!isPlaying)
    }
    return (
        <>
            {/* <ReactJkMusicPlayer
                audioLists={[{src: SONG}]}
                 /> */}
            {/* <ReactAudioPlayer
                ref={audio}
                // style={{opacity: 1, height: 10}}
                src={SONG}
                autoPlay
                muted={isPlaying}
                loop={true}
                controls
            /> */}
            <audio className="audio-element">
                <source src={SONG}></source>
            </audio>
            <div className={classes.root}>

                <div className={classes.desktop}>
                    <div style={{display: 'flex', flex: 1, height: '100%', width: '100%', alignItems: 'center', }}>

                        <Logo />
                        <Menu checked={checked} />
                        <div className={classes.backGround}>
                            <Box component="span" style={{marginRight: 0, marginTop: 8}}>
                           
                                <Text title="Music" fontSize={20} lineHeight={"23px"} variant="text" onPress={() => {}} />
                            </Box>

                            {
                                isPlaying ?
                                    <IconButton
                                        style={{padding: 5}}
                                        onClick={() => handleSound(isPlaying)}>
                                        <VolumeUpIcon style={{fontSize: 20, color: '#fff'}} />
                                    </IconButton>
                                    :
                                    <IconButton
                                        style={{padding: 5}}
                                        onClick={() => handleSound(isPlaying)}>
                                        <VolumeMuteIcon style={{fontSize: 20, color: '#fff'}} />
                                    </IconButton>
                            }

                        </div>

                    </div>

                </div>


            </div>
            <div className={classes.mobile}>
                <Menu checked={checked} />
                <Logo isMobile={true} />

                <div className={classes.backGround}>

                    {
                        isPlaying ?
                            <IconButton
                                style={{padding: 5}}
                                onClick={() => handleSound(isPlaying)}>
                                <VolumeUpIcon style={{fontSize: 20, color: '#fff'}} />
                            </IconButton>
                            :
                            <IconButton
                                style={{padding: 5}}
                                onClick={() => handleSound(isPlaying)}>
                                <VolumeMuteIcon style={{fontSize: 20, color: '#fff'}} />
                            </IconButton>
                    }
                </div>


            </div>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-end',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            // display: 'flex',
            // paddingLeft: 50,
            paddingRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            // display: 'flex',
            // paddingLeft: 10,
            left: 0,
            top: 20,
            paddingRight: 0,
        },
    },
    mobile: {
        display: 'none',
        width: '100%',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            // paddingLeft: 50,

            paddingRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            // paddingLeft: 10,

            paddingRight: 0,
        },
    },
    desktop: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-end',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'none',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'flex-end',
            height: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'flex-end',
            height: '100%',
        },
    },
    backGround: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'rgba(0,0,0,0.3)'
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 0,
            width: '100%',
            bottom: 15,
            // paddingRight: 0,
        },

    },


}))
