import React from "react";
import { useLocation } from "react-router-dom";
import Loader from "react-js-loader";
import { AnimatePresence, motion } from "framer-motion";

import LogoImg from "../assets/images/tmLogo.png";

export const Wrapper = ({ children, dot, loader }) => {
  const { pathname } = useLocation();
  let isDotSelected = pathname.includes("forgot");
  if (!isDotSelected) {
    isDotSelected = pathname.includes("welcome");
  }

  return (
    <section className='wrapper welcome center'>
      <div className={`flex`}>
        <img src={LogoImg} className='sm-img top-position' />
        {children}
      </div>

      {/* {dot ? (
        ""
      ) : (
        <ul className='dot-wrapper'>
          <li className={`dot ${!isDotSelected && "dot-active"}`}></li>
          <li className={`dot ${isDotSelected && "dot-active"}`}></li>
        </ul>
      )} */}

      <AnimatePresence>
        {loader && (
          <motion.div className='overlay center'>
            <Loader type='spinner-default' bgColor={"#FFFFFF"} size={80} />
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};
