import React from "react";
import {Modal, Link, Box } from "@mui/material";
import { DoneIcon, RelaxIcon } from "../../../assets/images";
import { useHistory } from "react-router";

function Model(props) {
  const {
    open,
    handleClose,
    classes,
    submitChildMood,
    submitType
  } = props;
  const history = useHistory();

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ borderRadius: '8px' }}
    >
      <Box className={classes.modelBox}>
        <div className="flex w-100">
            <div className="lg-input-container mt-0 scroll">
              <h3
                className="l-heading mt-0"
                style={{ color: "#000", fontSize: 24, padding: 8 }} 
              >
                 Thank you for completing a Feelyou music session. We hope you are feeling better. If you haven't yet, please fill out our quick 2 question survey regarding your experience <Link href="https://forms.gle/LF6jZQWRTGdiRi686"  target="_blank" className={classes.link}>click here</Link>
              </h3>
              <div className={'center'}>
                <button
                  type="submit"
                  className="btn-outline text mt-20 pointer"
                  style={{ backgroundColor: "#9836fa" }}
                  onClick={submitType}
                >
                  Start New Session
                </button>
                <button
                  type="submit"
                  className="btn-outline text mt-20 pointer"
                  style={{ backgroundColor: "#9836fa" }}
                  onClick={submitChildMood}
                >
                  Logout
                </button>
              </div>
            </div>
        </div>
      </Box>
    </Modal>
  );
}

export default Model;
